<template>
  <b-card no-body class="mb-1">
    <b-card-header :class="!visible ? 'p-1' : 'px-1 pt-1 pb-0'" class="cursor-pointer" @click="visible = !visible"
      style="transition: all 0.5s ease">
      <div class="d-flex justify-content-between w-100">
        <h5>{{ $t("Filters") }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body class="pt-0">
        <b-row>
          <b-col cols="12" md="3" class="d-flex flex-column">
            <label>Month</label>
            <month-picker-input :no-default="true" @change="showDate">
            </month-picker-input>
          </b-col>
          <b-col cols="12" md="3" class="d-flex flex-column">
            <label>Date Range</label>
            <div class="d-flex align-items-center flex-wrap">
              <flat-pickr v-model="filters.date_start" :config="fConfig" class="form-control"
                placeholder="MM-DD-YYYY H:i" />
              <flat-pickr v-model="filters.date_end" :config="uConfig" class="form-control ml-xl-1"
                placeholder="MM-DD-YYYY H:i" />
            </div>
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>User</label>
            <v-select v-model="filters.user_id" class="w-100" :options="userOptions" :reduce="(value) => value.id"
              label="name" @keyup.enter="searchFilter" @search="searchUser">
              <template slot="option" slot-scope="option">
                <div class="d-flex">
                  <b-avatar size="26" variant="light" :src="option.avatar" />
                  <span class="ml-1">
                    {{ option.name }} {{ option.lastname }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <b-avatar size="26" variant="light" :src="option.avatar" />
                  <span class="ml-1">
                    {{ option.name }} {{ option.lastname }}</span>
                </div>
              </template>
            </v-select>
          </b-col>
          <b-col cols="12" md="3" class="d-flex flex-column">
            <label>Facility</label>
            <v-select v-model="filters.facility_id" class="rounded" :options="facilityOptions"
              :reduce="(value) => value.id" label="name" @keyup.enter="searchFilter"></v-select>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col cols="4">
            <b-form-radio-group
              v-model="itemView"
              class="ml-1 list item-view-radio-group mt-2"
              buttons
              size="sm"
              button-variant="outline-primary"
              @change="$emit('viewTable', itemView)"
            >
              <b-form-radio
                v-for="option in itemViewOptions"
                :key="option.value"
                :value="option.value"
              >
                <feather-icon :icon="option.icon" size="18" />
              </b-form-radio>
            </b-form-radio-group>
          </b-col> -->
          <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
            <b-button variant="warning" @click="resetFilter" class="mr-1">
              {{ $t("Clear") }}
            </b-button>
            <b-button variant="primary" @click="searchFilter" :disabled="loading">
              <span v-if="!loading">{{ $t("Search") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BAvatar,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { MonthPickerInput } from 'vue-month-picker'
import SpinnerLoading from "@/components/SpinnerLoading";
import vSelect from "vue-select";

import axiosUser from "@/services/admin/user";
import axiosFacility from "@/services/admin/facilities";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    vSelect,
    flatPickr,
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BFormRadioGroup,
    BFormRadio,
    MonthPickerInput,
    SpinnerLoading,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.getUsers();
    this.getFacilities();
  },
  data() {
    return {
      userOptions: [],
      facilityOptions: [],
      fConfig: fromConfig({ isStatic: true, dateFormat: "m-d-Y", defaultHour: false }),
      uConfig: untilConfig({ isStatic: true, dateFormat: "m-d-Y", defaultHour: false }),
      configMonth: {
        dateFormat: "F Y",
        // static: true,
        mode: 'month',
        enable: [
          function (date) {
            return date <= new Date();
          }
        ]
      },
      clearTimeoutBuscador: "",
      visible: true,
      itemView: "table-view",
      itemViewOptions: [
        { icon: "AlignJustifyIcon", value: "table-view" },
        { icon: "ListIcon", value: "card-view" },
      ],
    };
  },
  methods: {
    showDate({ monthIndex, year }) {
      const firstDay = new Date(year, (monthIndex - 1), 1);
      const lasttDay = new Date(year, (monthIndex - 1) + 1, 0);

      const firstDayFormat = (firstDay.getMonth() + 1).toString().padStart(2, '0') + '-' + firstDay.getDate().toString().padStart(2, '0') + '-' + firstDay.getFullYear();
      const lastDayFormat = (lasttDay.getMonth() + 1).toString().padStart(2, '0') + '-' + lasttDay.getDate().toString().padStart(2, '0') + '-' + lasttDay.getFullYear();

      this.filters.date_start = firstDayFormat;
      this.filters.date_end = lastDayFormat;

    },
    getUsers() {
      const data = {
        role: ["FRONT-DESK", "OMA", "NURSE"],
        status: true,
      };
      axiosUser
        .userFilter(20, data)
        .then(({ registro: { data } }) => {
          this.userOptions = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFacilities() {
      axiosFacility.facilityList(20).then(({ data }) => {
        this.facilityOptions = data;
      });
    },
    searchUser(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["FRONT-DESK", "OMA", "NURSE"],
          status: true,
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosUser.userFilter(10, datos).then(({ registro: { data } }) => {
            this.userOptions = data;
            loading(false);
          });
        }, 400);
      }
    },
    resetFilter() {
      const filter = this.filters;
      for (const key in filter) {
        filter[key] = null;
      }
    },
    searchFilter() {
      this.visible = false;
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        const element = this.filters[iterator];
        if (element == "" || element == null) {
          cont++;
        }
      }

      this.$emit("searchFilter", JSON.parse(JSON.stringify(filter)));
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.flatpickr-wrapper {
  width: 50% !important;
}

.month-picker__container {
  z-index: 9 !important;
}

.month-picker-input-container {
  width: 100% !important;
}

.month-picker-input {
  width: 100% !important;
  height: 2.8rem !important;
}
</style>
