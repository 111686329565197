<template>
  <b-card no-body class="mb-1">
    <b-card-header
      :class="!visible ? 'p-1' : 'px-1 pt-1 pb-0'"
      class="cursor-pointer"
      @click="visible = !visible"
      style="transition: all 0.5s ease"
    >
      <div class="d-flex justify-content-between w-100">
        <h5>{{ titleReport }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card body-class="p-1">
        <!-- <b-row class="mb-1 info-footer">
          <b-col
            class="d-flex align-items-center justify-content-start flex-wrap mr-auto"
            cols="auto"
          >
            <div class="border-left">
              <b-dropdown text="Copy report" variant="flat-primary">
                <b-dropdown-item
                  v-for="(types, index) in optionsDocument"
                  :key="index"
                  @click="downloadDocument(types)"
                >
                  {{ types }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row> -->

        <div v-if="viewTable === 'card-view'">
          <b-row class="">
            <b-col md="2" v-for="(user, index) in items" :key="index">
              <CardUser :name="user.user_name" :summary="user.resumen_data" />
            </b-col>
          </b-row>
        </div>
        <template v-else>
          <b-table
            hover
            bordered
            responsive
            primary-key="id"
            class="position-relative"
            show-empty
            empty-text="No matching records found"
            :items="items"
            :fields="fields"
            no-border-collapse
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <SpinnerLoading />
              </div>
            </template>

            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <!-- @change="toggleDetailSurvey(row)" -->
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-tabs content-class="">
                <b-tab title="Daily" active>
                  <b-table
                    hover
                    bordered
                    responsive
                    primary-key="id"
                    class="position-relative"
                    show-empty
                    empty-text="No matching records found"
                    :items="row.item.bonus_data"
                    :fields="fieldsChildren"
                    no-border-collapse
                    foot-clone
                  >
                    <template #cell()="data">
                      <span>{{
                        isFieldDolar(data.field.key)
                          ? `$${data.item[data.field.key]}`
                          : `${data.item[data.field.key]}`
                      }}</span>
                    </template>

                    <template #foot()="">
                      <span></span>
                    </template>
                    <template #foot(total_bonus)="">
                      $ {{ calculateTotalForItem(row.item) }}
                    </template>
                  </b-table>
                </b-tab>
                <b-tab title="OMA">
                  <b-table
                    hover
                    bordered
                    responsive
                    primary-key="id"
                    class="position-relative"
                    show-empty
                    empty-text="No matching records found"
                    :items="row.item.work_omas"
                    :fields="fieldsOMA"
                    no-border-collapse
                  >
                  </b-table>
                </b-tab>
              </b-tabs>
            </template>
          </b-table>
        </template>
      </b-card>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BTable,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import CardUser from "./CardUser";

export default {
  components: {
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BTabs,
    BTab,

    SpinnerLoading,
    CardUser,

    vSelect,
  },
  props: {
    titleReport: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    viewTable: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
      fields: [
        {
          key: "show_details",
          label: "Show",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "user_name",
          label: "Name",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.priority_vpd",
          label: "Priority AVG",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.bonus",
          label: "Bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.ekg_quantity",
          label: "Total EKG",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.ekg",
          label: "Bonus EKG",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.sonograms_quantity",
          label: "Total USG",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.sonograms",
          label: "Bonus USG",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.semaglutide_quantity_x4_units",
          label: "Total SMG (x4)",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.semaglutide_bonus_x4_units",
          label: "Bonus SMG (x4)",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.mounjaro_quantity_x4_units",
          label: "Total Mounjaro (x4)",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.mounjaro_bonus_x4_units",
          label: "Bonus Mounjaro (x4)",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.secondary_bonus",
          label: "Secondary bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.total_bonus",
          label: "Total bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          key: "resumen_data.total_bonus",
          label: "Total bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
      ],
      fieldsChildren: [
        {
          label: "Day",
          key: "day",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Facility",
          key: "facility_name",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "OMA",
          key: "oma_name",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Gross sale",
          key: "total",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Net income",
          key: "net_sale",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "bonus",
          key: "bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Total EKG",
          key: "ekg_quantity",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Bonus EKG",
          key: "ekg",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Total USG",
          key: "sonograms_quantity",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Bonus USG",
          key: "sonograms",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Total SMG (x4)",
          key: "semaglutide_quantity_x4_units",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Bonus SMG (x4)",
          key: "semaglutide_bonus_x4_units",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
				{
          label: "Total Mounjaro",
          key: "mounjaro_quantity_x4_units",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Bonus Mounjaro",
          key: "mounjaro_bonus_x4_units",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Secondary bonus",
          key: "secondary_bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "total bonus",
          key: "total_bonus",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
      ],
      fieldsOMA: [
        {
          label: "Name",
          key: "oma_name",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "Days",
          key: "worked_days",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
        {
          label: "AVG",
          key: "avg",
          sortable: true,
          class: "th-class-adjusted-admindata",
        },
      ],
      optionsDocument: ["PDF", "Excel"],
    };
  },
  methods: {
    calculateTotalForItem(item) {
      return item.bonus_data.reduce((total, item) => {
        total += Number(item.total_bonus);
        return total;
      }, 0);
    },
    isFieldDolar(field){
      const dataField = ['total','net_sale', 'bonus', 'ekg', 'sonograms', 'semaglutide_bonus_x_units', 'semaglutide_bonus_x4_units' ,'secondary_bonus', 'total_bonus']
      return dataField.includes(field)
    }
  },
};
</script>

<style></style>
