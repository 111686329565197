<template>
  <div>
    <BonusFilter
      @searchFilter="searchFilter"
      @viewTable="viewTable"
      :filters="filters"
      :loading="loading"
    />
    <BonusStatistic :facilityData="facilityData" />
    <div v-for="(roleData, roleName) in rolesData" :key="roleName">
      <RoleCollapse
        :titleReport="roleName"
        :items="parseArray(roleData)"
        :viewTable="viewTableBonus"
      />
    </div>

    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import ToastNotification from "@/components/ToastNotification";

import RoleCollapse from "./components/RoleCollapse";
import BonusStatistic from "./BonusStatistic";
import BonusFilter from "./BonusFilter";

import { formatPhone } from "@/mixins/formatPhone.js";
import axiosBonusData from "@/services/admin/admin-data/bonus-data";

export default {
  components: {
    ToastNotification,

    RoleCollapse,
    BonusFilter,
    BonusStatistic,
  },
  data() {
    const dateUsa = new Date().toLocaleDateString("en-US");
    const date = dateUsa.split("/").join("-");
    return {
      formatPhone,
      dataFilter: null,

      filters: {
        date_start: `${date}`,
        date_end: `${date}`,
        user_id: null,
        facility_id: null,
      },
      rolesData: [],
      facilityData: [],
      viewTableBonus: "",
      loading: false,
    };
  },
  methods: {
    searchFilter(value) {
      this.loading = true;
      this.dataFilter = value;
      axiosBonusData
        .adminDataFilter(value)
        .then(({ users_data, facilities_data }) => {
          const parseArray = this.parseArray(users_data);
          const rolesData = {};

          const nameRoles = Object.keys(parseArray[0]).sort((a, b) => {
            if (a > b) return -1;
          });

          nameRoles.forEach((role) => {
            const dataReturned = this.parseArray(parseArray[0][role]);
            rolesData[role] = dataReturned.map((itemDetail) => {
              if (Number(itemDetail.resumen_data.priority_vpd) >= 220) {
                return {
                  ...itemDetail,
                  _cellVariants: { "resumen_data.total_bonus": "success" },
                };
              }
              if (
                Number(itemDetail.resumen_data.priority_vpd) < 220 &&
                Number(itemDetail.resumen_data.secondary_bonus) > 0
              ) {
                return {
                  ...itemDetail,
                  _cellVariants: { "resumen_data.secondary_bonus": "success" },
                };
              }
              return itemDetail;
            });
          });

          this.loading = false;
          this.rolesData = rolesData;
          this.facilityData = facilities_data;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    downloadDocument(event) {
      axiosAD.adminDataExport(this.dataFilter).then((res) => {
        const blob = new Blob([res]);
        const filename = `admin_data_${this.dataFilter.date_start}_to_${this.dataFilter.date_end}.xlsx`;

        const urlExcel = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = urlExcel;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(urlExcel);
        document.body.removeChild(a);
      });
    },
    parseArray(array) {
      if (!Array.isArray(array)) {
        return [array];
      }
      return array;
    },
    viewTable(event) {
      this.viewTableBonus = event;
    },
  },
};
</script>
<style lang="scss">
.th-class-adjusted-admindata {
  padding: 0.5rem 0.5rem !important;
}
.th-class-adjusted-admindata.names {
  width: 17rem;
}
</style>
