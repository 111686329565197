import { userAxios } from '@/services'

const adminDataFilter = async (data) => {
    try {
        return await userAxios.get(`adminData/bonus`, { params: { ...data } }).then(res => res.data)
    } catch (error) {
    }
}

const semaglutideFilter = async (data, pagination, page) => {
    try {
        return await userAxios.get(`adminData/semaglutide/${pagination}?page=${page}`, { params: { ...data } }).then(res => res.data)
    } catch (error) {
    }
}

const semaglutideFilterData = async () => {
    try {
        return await userAxios.get(`treatments/semaglutides`).then(res => res.data)
    } catch (error) {
    }
}

export default {
    adminDataFilter,
    semaglutideFilter,
    semaglutideFilterData
}